import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { SEO } from '@components/Structural';
import { makeStyles, Theme } from '@material-ui/core';

import { useAuth } from '@hooks';
import SuccessfullyCreated from '@components/SuccessfullyCreated/SuccessfullyCreated';
import InvestAndP from '@components/Invest/SandP';

const InvestSandP = () => {
  useAuth({ redirectIfNotLogged: true });
  const [isCreate, setCreate] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const classes = useStyles();
  const [depositValue, setDepositValue] = useState<number>(0);

  return (
    <>
      <SEO />
      {isCreate ? (
        <SuccessfullyCreated depositValue={depositValue} />
      ) : (
        <InvestAndP setCreate={setCreate} />
      )}
    </>
  );
};

export default InvestSandP;

const useStyles = makeStyles<Theme>((theme) => ({}));
