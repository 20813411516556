import React, { useContext, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { chartOptions } from '@components';

import { useStyles } from './SandP.style';
import { ButtonPrimary, ButtonText } from '../Reusables';
import { graphDataGoogle } from '../../data/graphDataGoogle';
import { parseNumber } from '@utils';
import { Route } from '@interfaces';
import { navigate } from 'gatsby';
import { GoalsContext } from '@context';

interface InvestAndPProps {
  setCreate: (value: boolean) => void;
}

const InvestAndP = ({ setCreate }: InvestAndPProps) => {
  const classes = useStyles();
  const { addGoal } = useContext(GoalsContext);

  const valueData = {
    PriceUpdate: [
      [
        [
          [
            2,
            '/m/016yss',
            null,
            'INDEXSP',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [
              ['/m/016yss'],
              'S&P 500',
              '.INX',
              '4,138.12',
              4138.12,
              '+1.87',
              '0.045 %',
              2,
              '8 may, 17:01 GMT-4',
              '/search?rlz=1C1SQJL_enAR922AR922&cs=1&ie=UTF-8&q=INDEXSP:+.INX&stick=H4sIAAAAAAAAAONgecRowi3w8sc9YSntSWtOXmNU5eIKzsgvd80rySypFBLnYoOyeKW4uTj1c_UNDM0qi4t5FrHyevq5uEYEB1gp6Hn6RQAAItD1MEkAAAA',
              '17:01 GMT-4',
              10,
              [1683579699],
            ],
            null,
            [4136.25, 2],
          ],
          null,
          ['/m/016yss'],
        ],
        [
          [
            2,
            '/m/0cqyw',
            null,
            'INDEXDJX',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [
              ['/m/0cqyw'],
              'Dow Jones Industrial Average',
              '.DJI',
              '33,618.69',
              33618.69,
              '−55.69',
              '0.17 %',
              3,
              '8 may, 17:01 GMT-4',
              '/search?rlz=1C1SQJL_enAR922AR922&cs=1&ie=UTF-8&q=INDEXDJX:+.DJI&stick=H4sIAAAAAAAAAONgecRozC3w8sc9YSmtSWtOXmNU4eIKzsgvd80rySypFBLjYoOyeKS4uDj0c_UNkgsry3kWsfJ5-rm4Rrh4RVgp6Ll4eQIAqJT5uUkAAAA',
              '17:01 GMT-4',
              120,
              [1683579699],
            ],
            null,
            [33674.38, 2],
          ],
          null,
          ['/m/0cqyw'],
        ],
        [
          [
            2,
            '/m/02853rl',
            null,
            'INDEXNASDAQ',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [
              ['/m/02853rl'],
              'Nasdaq Composite',
              '.IXIC',
              '12,256.92',
              12256.918,
              '+21.50',
              '0.18 %',
              2,
              '8 may, 17:15 GMT-4',
              '/search?rlz=1C1SQJL_enAR922AR922&cs=1&ie=UTF-8&q=INDEXNASDAQ:+.IXIC&stick=H4sIAAAAAAAAAONgecRoyi3w8sc9YSmdSWtOXmNU4-IKzsgvd80rySypFJLgYoOy-KR4uLj0c_UNjCxMjYtyeBaxCnn6ubhG-DkGuzgGWinoeUZ4OgMARJMy4U8AAAA',
              '17:15 GMT-4',
              10,
              [1683580559],
            ],
            null,
            [12235.413, 2],
          ],
          null,
          ['/m/02853rl'],
        ],
        [
          [
            2,
            '/m/04zvfw',
            null,
            'INDEXRUSSELL',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [
              ['/m/04zvfw'],
              'Russell 2000 Index',
              'RUT',
              '1,754.47',
              1754.4703,
              '−5.41',
              '0.31 %',
              3,
              '8 may, 16:30 GMT-4',
              '/search?rlz=1C1SQJL_enAR922AR922&cs=1&ie=UTF-8&q=INDEXRUSSELL:+RUT&stick=H4sIAAAAAAAAAONgecRowi3w8sc9YSntSWtOXmNU5eIKzsgvd80rySypFBLnYoOyeKW4uTj1c_UNTKrK0sp5FrEKevq5uEYEhQYHu_r4WCkEhYYAABblKERNAAAA',
              '16:30 GMT-4',
              10,
              [1683577808],
            ],
            null,
            [1759.8794, 2],
          ],
          null,
          ['/m/04zvfw'],
        ],
      ],
    ],
  };

  const from = new Date();
  from.setFullYear(from.getFullYear() - 1);
  from.setMonth(from.getMonth());

  const parsedSheet = graphDataGoogle[2]?.replaceAll('],[', '];[').split(';');
  const finalDataValue: any = [];
  const finalDataTime: any = [];
  parsedSheet.filter((e, i) =>
    i % 2
      ? finalDataValue.push(e.split(',')[0].replaceAll('[', ''))
      : finalDataTime.push([
          e.split(',')[2].replaceAll('[', '') +
            '-' +
            e.split(',')[1].replaceAll('[', '') +
            '-' +
            e.split(',')[0].replaceAll('[', ''),
        ])
  );

  const defaultDataPrimary = {
    labels: finalDataTime,
    datasets: [
      {
        fill: true,
        label: 'Valor',
        data: finalDataValue,
        borderColor: '#0a5669',
        backgroundColor: ' #C4E6EB',
        pointStyle: 'circle',
        radius: 6,
        pointRadius: 1,
        lineTension: 0.3,
      },
    ],
  };
  const createGoal = () => {
    addGoal({
      name: 'S&P500',
      goal: 1,
      period: 0,
      investmentFrequency: '1',
      type: 'S&P500',
      monthlyInvestment: 1,
    });
  };

  const [graphData, setGraphData] = useState(defaultDataPrimary);
  return (
    <div>
      <div className={classes.backgroundGreen} />
      <section className={classes.sectionTop}>
        <div className={classes.titleTop}>
          <div style={{cursor: "pointer"}} onClick={()=> navigate("/Investments")}>
            <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/arrowLeft_icon.svg" />
          </div>
          <div className={classes.title}>
            <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/partners01_icon.svg" />{' '}
            INVERSIÓN S&P
          </div>
        </div>
        <div className={classes.titlePrincipal}>S & P 500</div>
        <div className={classes.values}>
          <div>
            <div className={classes.titleValue}>Valor de hoy</div>
            <div className={`${classes.value} ${classes.fontWeight500}`}>
              {' '}
              {parseNumber(valueData.PriceUpdate[0][0][0][17][4], 2)}
            </div>
            <div className={`${classes.bottomValue} ${classes.uppercase}`}>
              usd
            </div>
          </div>
          <div className={classes.line} />
          <div>
            <div className={classes.titleValue}>Rentabilidad</div>
            <div className={`${classes.value} ${classes.fontWeight300}`}>
              <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/income_icon.svg" />{' '}
              <span>
                {' '}
                {(
                  (finalDataValue[finalDataValue.length - 1] * 100) /
                    finalDataValue[0] -
                  100
                ).toFixed(2)}
              </span>
            </div>
            <div className={`${classes.bottomValue}`}>este año</div>
          </div>
        </div>
      </section>{' '}
      <div className={classes.center}>
        <section className={classes.sectionTable}>
          <Line options={chartOptions} data={graphData} />
        </section>
        <div className={classes.dateActual}>
          Última actualización: 11/05/2023
        </div>
      </div>
      <section className={classes.sectionTextInvest}>
        <div>
          La inversión en el índice S&P (Standard & Poor's) consiste en invertir
          en una cartera de acciones que representan el desempeño de las{' '}
          <span> 500 empresas más grandes y exitosas</span> de Estados Unidos.{' '}
        </div>
        <section className={classes.investments}>
          <span>Invertirás en</span>
          <div className={classes.flex}>
            <div>
              <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/apple_logo.svg" />
            </div>{' '}
            <div>
              <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/google_logo.svg" />
            </div>{' '}
            <div>
              <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/amazon_logo.svg" />
            </div>{' '}
            <div>
              <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/microsoft_logo.svg" />
            </div>{' '}
            <div>
              <img src="https://storage.googleapis.com/rokinapp-images/finances/SandP/visa_logo.svg" />
            </div>
          </div>
        </section>
        <section className={classes.containerButtons}>
          <ButtonPrimary
            noDegraded
            text="Crear Inversión S&P"
            onClick={() => {
              createGoal();
              setCreate(true);
            }}
          />
          <ButtonText
            className="secondary"
            text="Cancelar"
            onClick={() => {
              navigate(Route.investments);
            }}
          />
        </section>
      </section>
    </div>
  );
};
export default InvestAndP;
