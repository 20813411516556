import Successful from '@components/Structural/Successful/Successful';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { ButtonOutlined, ButtonPrimary } from '..';
import { AppContext, NewInvestContext } from '@context';
import { useCurrentUser, useGoals } from '@apollo';
import { MovementType, PortfolioLocationCategory, Route } from '@interfaces';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core';

const SuccessfullyCreated = ({ depositValue }) => {
  const classes = useStyles();
  const { openNoGoalWarningModal, openNoPersonalDataWarningModal } =
    useContext(AppContext);
  const { user: currentUser } = useCurrentUser();
  const { goals } = useGoals();
  const [goalId, setGoalId] = useState<number>(null);
  useEffect(() => {
    if (goals.length > 0) {
      const goal = goals.filter((obj) => obj.name === 'S&P500');
      if (goal[0] !== undefined) {
        setGoalId(goal[0].id);
      }
    }
  }, [goals]);
  const { openAddFundsModal, startFlow } = useContext(NewInvestContext);

  const handleClick = useCallback(() => {
    if (goals.length == 0) {
      openNoGoalWarningModal();
    } else {
      if (
        !currentUser?.isIdentityValidated ||
        !currentUser?.isPersonalDataCompleted ||
        !currentUser?.isSpecialDataCompleted
      ) {
        openNoPersonalDataWarningModal();
      } else {
        openAddFundsModal(goalId, '', 0, true);
        startFlow({
          movementType: MovementType.DEPOSIT,
          newInvestorProfile: currentUser?.investorProfile?.investorProfile,
          suggestedPortfolio: true,
          investmentLocation: PortfolioLocationCategory.CHILE_AND_WORLD,
          suggestedPortfolioAmount: depositValue,
        });
      }
    }
  }, [goalId, goals]);

  return (
    <Successful
      title="Tu objetivo S&P se ha creado con éxito"
      depositValue={0}
      firstFundSelection={''}
    >
      <div className={classes.cardGreen}>
        <div className={classes.subtitle}>
          Transfiere inmediatamente para comenzar a ahorrar o vuelve a Mis
          Inversiones
        </div>
        <div className={classes.dataRight}>
          <div className="flex">
            <img
              src={
                'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/partners01_icon%20(1).svg'
              }
            />
            <div className={classes.right}>
              <span className="title">Portafolio S&P</span>
            </div>
          </div>{' '}
          <div className={classes.goal}>
            <div>
              <span>tienes</span>
              <div>$0</div>
            </div>
            <div>
              <span>Rentabilidad</span>
              <div>8%</div>
            </div>
          </div>
        </div>

        <div className={classes.button}>
          <ButtonPrimary noDegraded onClick={handleClick} text="Hacer aporte" />
          <ButtonOutlined
            text="Volver a Mis Inversiones"
            onClick={() => navigate(Route.investments + '/?#objectives')}
          />
        </div>
      </div>
    </Successful>
  );
};
export default SuccessfullyCreated;

const useStyles = makeStyles((theme) => ({
  containerData: {
    display: 'flex',
    background: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    '& >div': {
      width: '100%',
    },
    paddingLeft: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '0.875rem',
    margin: '3rem auto 2rem',
    textAlign: 'center',
  },
  cardGreen: {
    background: '#00323c',
    borderRadius: '20px 20px 0 0',
    height: '27rem',
  },
  subtitle: {
    width: 350,
    padding: '30px 0 30px',
    fontSize: '0.875rem',
    textAlign: 'center',
    color: 'white',
    margin: '0 auto',
  },
  dataRight: {
    background: 'white',
    boxShadow: '1px 5px 10px rgba(0,0,0,0.2)',
    borderRadius: 16,
    flexDirection: 'column',
    marginBottom: '3rem',
    margin: '1rem auto 3rem',
    display: 'flex',
    alignItems: 'center',
    width: 350,
    height: '9rem',
    '& img': {
      width: 40,
      padding: '0.3rem',
      marginLeft: '2rem',
      background: theme.palette.primary.dark,
      borderRadius: '100%',
      margin: 18,
    },
    '& .flex': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
  },
  right: {
    paddingLeft: '0.5rem',
    '& span': {
      display: 'block',
      fontWeight: 'bold',
    },
  },
  goal: {
    paddingTop: '0.5rem',
    display: 'flex',
    gap: '2rem',
    width: '100%',
    justifyContent: 'space-around',
    '& >div': {
      '&>div': {
        color: '#959595',
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
      },
      '&>span': {
        color: '#959595',
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        fontWeight: 'bold',
      },
    },
    '& .title': {
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: 'Montserrat',
      display: 'flex',
      gap: '0.2rem',
      alignItems: 'center',
      justifyContent: 'space-around',
      '& svg': {
        width: 20,
        color: theme.palette.primary.main,
      },
    },
    '& .subtitle': {
      fontSize: 12,
      fontWeight: 500,
      color: '#6B6B6B',
      paddingTop: '0.2rem',
    },
  },
  button: {
    justifyContent: 'space-between',
    display: 'block',
    width: 350,
    margin: '0 auto',
    '& > button': {
      margin: '0 auto 14px',
      width: '100%',
      fontSize: 16,
      height: 50,
      color: '#54e5ff',
    },
  },
  imgMobile: {
    '& img': {
      margin: '0 auto 2rem',
      width: '250px',
    },
  },
}));
